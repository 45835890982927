/* Full-screen overlay loader */
.loader-overlay {
    position: fixed; /* Position relative to the viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Darker semi-transparent background */
    z-index: 9999; /* Ensures the loader stays on top of all content */
    display: flex;
    justify-content: center;
    align-items: center;
    animation: pulse 2s infinite; /* Pulse animation for background */
  }
  
  /* Pulse effect for overlay */
  @keyframes pulse {
    0% {
      background-color: rgba(0, 0, 0, 0.5);
    }
    50% {
      background-color: rgba(0, 0, 0, 0.7);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  
  /* Spinner with gradient animation */
  .spinner-border {
    width: 7rem; /* Larger spinner size */
    height: 7rem;
    border-width: 0.4rem;
    border-top: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #0d6efd; /* Blue color for bottom */
    border-left: 10px solid #ff6347; /* Orange color for left */
    border-radius: 50%;
    animation: spin 1.5s linear infinite, gradient 1.5s linear infinite; /* Added gradient animation */
  }
  
  /* Spinner rotation animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Gradient animation for spinner */
  @keyframes gradient {
    0% {
      border-left-color: #ff6347; /* Red */
      border-bottom-color: #0d6efd; /* Blue */
    }
    50% {
      border-left-color: #0d6efd; /* Blue */
      border-bottom-color: #ff6347; /* Red */
    }
    100% {
      border-left-color: #ff6347; /* Red */
      border-bottom-color: #0d6efd; /* Blue */
    }
  }
  
  /* Loading text below spinner */
  .loader-text {
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 1rem;
  }

  .loader-content {
    display: flex;
    flex-direction: column; /* Stack spinner above text */
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    text-align: center;
}
  